export const Example_data = [
  {
    name: "General City",
    cover: "/public/generalcitycover.jpg",
    logo: "/public/generalcitylogo.png",
    images: [
      "/public/generalcity1.jpg",
      "/public/generalcity2.jpg",
      "/public/generalcity3.jpg",
      "/public/generalcity4.jpg",
    ],
    desc: "generalcity-desc",
  },
  {
    name: "Marmots Ai Coin",
    cover: "/public/marmotscover.jpg",
    logo: "/public/marmotslogo.png",
    images: ["/public/marmots1.jpg"],
    desc: "marmots-desc",
  },
  {
    name: "Avam Pardazan",
    cover: "/public/avamcover.jpg",
    logo: "/public/avamlogo.png",
    images: ["/public/avam1.jpg"],
    desc: "avam-desc",
  },
  {
    name: "1924",
    cover: "/public/1924cover.png",
    logo: "/public/1924logo.svg",
    images: ["/public/1924-1.png", "/public/1924-2.jpg", "/public/1924-3.jpg"],
    desc: "1924-desc",
  },
  {
    name: "Emporium point",
    cover: "/public/epskancover.png",
    logo: "/public/epskanlogo.png",
    images: ["/public/epskan1.jpg"],
    desc: "epskan-desc",
  },
  {
    name: "T1 Vision",
    cover: "/public/t1cover.jpg",
    logo: "/public/t1logo.png",
    images: ["/public/t3.jpg", "/public/t2.jpg", "/public/t1.jpg"],
    desc: "t1-desc",
  },

  {
    name: "Urameta",
    cover: "/public/urametacover.png",
    images: ["/public/urameta.png", "/public/urameta2.png"],
    logo: "/public/urametalogo.png",
    desc: "urameta-desc",
  },
  {
    name: "drgold",
    cover: "/public/drgoldcover.png",
    images: [
      "/public/drgold (2).jpg",
      "/public/drgold (1).jpg",
      "/public/drgold (3).jpg",
    ],
    logo: "/public/drgoldlogo.png",
    desc: "drgold-desc",
  },
  {
    name: "sarapasha",
    cover: "/public/sarapashacover.jpeg",
    images: [
      "/public/sarapasha1.png",
      "/public/sarapasha2.png",
      "/public/sarapasha3.png",
    ],
    logo: "/public/sarapasha-logo.png",
    desc: "sarapasha-desc",
  },
  {
    name: "iaclub",
    cover: "/public/iaclobcover.png",
    images: [
      "/public/iaclub1.png",
      "/public/iaclub2.png",
      "/public/iaclub3.png",
      "/public/iaclub4.png",
      "/public/iaclub5.png",
    ],
    logo: "/public/iaclublogo.png",
    desc: "iaclub-desc",
  },
  {
    name: "shame",
    cover: "/public/shamecover.png",
    images: [
      "/public/shame.png",
      "/public/shame2.png",
      "/public/shame3.png",
      "/public/shame4.png",
    ],
    logo: "/public/shamlogo.png",
    desc: "shame-desc",
  },
];
